<template>
<div>
  <!--TITULOS  -->
  <div class="air__utils__heading"> <h5>Reporte de Requerimiento Logístico</h5> </div>
  <my-breadcrumbs :routes="routes"/>
  <!--TITULOS  -->
  <!--BOTONERA  -->
<div class="row justify-content-end mr-2">
  <b-button-group>
    <b-button  variant="info" @click="OpenModal" pill>
      <div class="d-none d-md-block" v-b-tooltip.hover title="Descargar">
        <b-icon icon="download"/> Descargar
      </div>
      <div class="d-md-none" v-b-tooltip.hover title="Descargar">
        <b-icon icon="download"/>
      </div>
    </b-button>
  </b-button-group>
</div>
  <!--BOTONERA  -->
  <!--BARRA DE BUSQUEDA  -->
  <searchingBar :components="['pages']"
        :sortByOptions="[]"
        @Fectch="FetchTable"
        @filterValue="filterValue = $event"
        @sortValue="tableObj.sortValue = $event"
        @pageSize="tableObj.pagination.pageSize = $event"
        @currentPageValue="tableObj.currentPage = $event">
    <div class="row justify-content-start" slot="customFilters">
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <b-form-group id="service-group" label="Servicio" label-for="service">
          <!-- <b-form-select v-model="filterService" :options="ServiceCombo"
                         id="service" label="Servicio" @change="FetchTable()"
                         value-field="sap_programming_code" text-field="sap_programming_code">
            <template slot="first">
              <b-form-select-option value="" >Todos los Servicios</b-form-select-option>
            </template>
          </b-form-select> -->
          <a-select v-model="filterService" @change="FetchTable()" allowClear
                    placeholder="Número de Programación" style="width: 100%"
                    show-search :filter-option="filterOption">
            <a-select-option v-for="option in ServiceCombo" :key="option.sap_programming_code" :value="option.sap_programming_code">
              {{ option.sap_programming_code }}
            </a-select-option>
          </a-select>
        </b-form-group>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <globalPlantFilter @onChangeValue="FetchTable"  @filterValue="filterPlant = $event"/>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <b-form-group id="fromPickup-group" label="Fecha inicial"  label-for="fromPickup">
          <b-form-datepicker v-model="filterFromDate"
                             reset-button label-reset-button="Limpiar"
                             id="fromPickup" label="Fecha inicial" placeholder="Desde"/>
        </b-form-group>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <b-form-group id="toPickup-group" label="Fecha final"  label-for="toPickup">
          <b-form-datepicker v-model="filterToDate"
                             reset-button label-reset-button="Limpiar"
                             id="toPickup" label="Fecha final" placeholder="Hasta"/>
        </b-form-group>
      </div>
    </div>

    <div class="row" slot="customFilters">
      <div class="col-sm-12 col-md-6 col-lg-3">
        <b-form-group id="carrier-group" label="Transportista" label-for="carrier">
          <!-- <b-form-select v-model="filterCarrier" :options="CarrierCombo"
                          id="carrier" label="Transportista" @change="FetchTable()"
                          value-field="code" text-field="name">
            <template slot="first">
              <b-form-select-option value="" >Todos los Transportistas</b-form-select-option>
            </template>
          </b-form-select> -->
          <a-select v-model="filterCarrier" @change="FetchTable()" allowClear
                    placeholder="Transportista" style="width: 100%"
                    show-search :filter-option="filterOption">
            <a-select-option v-for="option in CarrierCombo" :key="option.code" :value="option.code">
              {{ option.name }}
            </a-select-option>
          </a-select>
        </b-form-group>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <b-form-group id="client-group" label="Cliente" label-for="client">
          <b-form-select v-model="filterClient" :options="ClientsCombo"
                          id="client" label="Cliente" @change="FetchTable()"
                          value-field="code" text-field="name">
            <template slot="first">
              <b-form-select-option value="" >Todos los Clientes</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <b-form-group id="status-group" label="Estatus" label-for="Estatus">
          <b-form-select v-model="filterEstatus" :options="ServiceCombo4"
                          id="estatus" label="Estatus" @change="FetchTable()"
                          value-field="value" text-field="text">
            <template slot="first">
              <b-form-select-option value="" >Todos los Estatus</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
    </div>
  </searchingBar>
  <!--BARRA DE BUSQUEDA  -->

  <!--TABLA-->
  <a-table
    :columns="tableObj.columns"
    :dataSource="tableObj.data"
    :pagination="tableObj.pagination"
    :loading="tableObj.loading"
    @change="FetchTable"
    :row-key="record => record.id"
    bordered
    :scroll="{ x: 1300 }"
  >
    <template slot="currents" slot-scope="servicePlanningsMaterials">
      <div v-if="Object.keys(servicePlanningsMaterials).length">
        <span v-for="item in servicePlanningsMaterials" :key="item.id">
          <b-icon icon="clipboard-check"></b-icon> {{ item.currents.name }}
        </span>
      </div>
      <span v-else>-</span>
    </template>

    <template slot="saprogramming" slot-scope="record">
      <small>
        <strong>
          No. de Programación SAP:
        </strong>
      </small>
      <small>
        {{ record.servicePlannings.sap_programming_code }}
      </small>
      <hr class="bg-primary">
      <small>
        <strong>
          Planta:
        </strong>
      </small>
      <small>
        {{ record.servicePlannings.workCenters.code }} - {{ record.servicePlannings.workCenters.name }}
      </small>
      <hr class="bg-primary">
      <small>
        <strong>
          Contrato:
        </strong>
      </small>
      <small>
        {{ record.servicePlannings.service_planeable.contract_code }}
      </small>
      <br>
      <small>
        <strong>
          Cliente:
        </strong>
      </small>
      <small>
        {{ (record.servicePlannings.service_planeable.meta) ? record.servicePlannings.service_planeable.meta.clientInfo : '' }}
      </small>
    </template>

    <template slot="service" slot-scope="record">
      <small>
        <strong>
          No. de Manifiestos:
        </strong>
      </small>
      <small>
        {{ record.servicePlannings.manifestPercentage.totalManifest }}
      </small>
      <hr class="bg-primary">
      <small>
        <strong>
          Tipo de Transporte:
        </strong>
      </small>
      <small>
        {{ (record.trucks) ? ((record.trucks.logisticsShipmentTypes) ? record.trucks.logisticsShipmentTypes.name : '-') : '' }}
      </small>
      <hr class="bg-primary">
      <small>
        <strong>
          Empresa Transportista:
        </strong>
      </small>
      <small>
        {{ (record.trucks) ? ((record.trucks.carrierCompanies !== null) ? record.trucks.carrierCompanies.name : '') : '' }}
      </small>
      <br>
      <small>
        <strong>
          Requerimiento Logístico:
        </strong>
      </small>
      <small>
        {{ (record.transportation_management !== null) ?  record.transportation_management : '-' }}
      </small>
    </template>

    <template slot="details" slot-scope="spcarrier">
      <a-descriptions bordered size="small"
                      :column="{ sm: 1, md: 2, lg: 2}">
        <a-descriptions-item label="Planta">
          <strong>{{spcarrier.servicePlannings.workCenters.name}} </strong>
        </a-descriptions-item>>
        <a-descriptions-item label="Generador">
          <generatorLogicInfo :service="spcarrier.servicePlannings" :jsonApiVuex="false"/>
        </a-descriptions-item>
        <a-descriptions-item label="No. de Manifiestos">
          <strong>{{spcarrier.servicePlannings.manifests.length}}</strong>
        </a-descriptions-item>
        <a-descriptions-item label="Tipo de Transporte">
          <span><strong>{{ (spcarrier.trucks.logisticsShipmentTypes.name !== null) ? spcarrier.trucks.logisticsShipmentTypes.name : '-' }}  </strong></span>
        </a-descriptions-item>
      </a-descriptions>
    </template>

    <template slot="dates" slot-scope="record">
      {{ (record.servicePlannings.servicePlanningsPeriods.pickup_dated_at === null) ? '-' : record.servicePlannings.servicePlanningsPeriods.pickup_dated_at }}
      {{ (record.servicePlannings.servicePlanningsPeriods.pickup_hour === null) ? '' : record.servicePlannings.servicePlanningsPeriods.pickup_hour }}
    </template>

    <template slot="weight" slot-scope="record">
      <div v-if="record.servicePlannings.servicePlanningsMaterials !== null">
        <div v-if="record.servicePlannings.servicePlanningsMaterials.length > 0">
          <div v-for="material in record.servicePlannings.servicePlanningsMaterials" :key="material.id">
          {{ material.currents.name }} - {{ material.container_capacity * material.container_quantity }} TON
          </div>
        </div>
        <div v-else>
          <span>
            0 | TON
          </span>
        </div>
      </div>
      <div v-else>
        <span>
          0 | TON
        </span>
      </div>
    </template>

    <template slot="status" slot-scope="record">
      <div v-if="record.trucks!== null">
        <div v-if="record.trucks.carrierCompanies !== null">
          <div v-if="record.trucks.carrierCompanies.name!=''">
            <span class="text-success">
              <strong>Asignado</strong>
            </span>
          </div>
          <div v-else>
            <span class="text-danger">
              <strong>Sin Asignar</strong>
            </span>
          </div>
        </div>
        <div v-else>
          <span class="text-danger">
            <strong>Sin Asignar</strong>
          </span>
        </div>
      </div>
      <div v-else>
        <span class="text-danger">
          <strong>Sin Asignar</strong>
        </span>
      </div>
    </template>
  </a-table>

  <!--TABLA-->

  <!-- MODAL -->
  <b-modal title="Confirmación de Descarga" ref="downloadModal" size="lg" @hide="CloseModal" >
    <b-container fluid>
      <my-spinner :load="spinnerLoad"/>
      <template v-if="!spinnerLoad">
        <div class="row justify-content-start" slot="customFilters">
          <div class="col-sm-12 col-md-6" >
            <b-form-group id="month-group" label="Mes" label-for="month">
              <b-form-select v-model="filterMonth" :options="bvMonthCombo"
                             id="month" label="Mes" @change="FetchTable()">
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-md-6" >
            <b-form-group id="year-group" label="Año" label-for="year">
              <b-form-select v-model="filterYear" :options="bvYearsCombo()"
                             id="year" label="Año" @change="FetchTable()">
              </b-form-select>
            </b-form-group>
          </div>
        </div>
        <b-alert show class="bg-real-blue-light"> Por motivos de desempeño del servidor solo es posible realizar la descarga del reporte de forma mensual, por favor confirme el mes que desea descargar.</b-alert>
        <fadeAlert :text="fadeMsg" variant="danger" v-model="fadeAlter"/>
        <div class="row justify-content-center my-3" v-if="fileUrl">
          <div class="col-12 text-center mt-2">
            <small class="text-secondary">
              Si el archivo no se descarga automáticamente, de click en el siguiente enlace
            </small>
          </div>
          <div class="col-12 text-center my-4">
            <b-link :href="fileUrl" variant="primary"  pill target="_blank" >
              <div class="d-none d-md-block" v-b-tooltip.hover title="Descarga">
                <b-icon icon="cloud-download-fill"/> Descarga aquí
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Descarga">
                <b-icon icon="cloud-download-fill"/>
              </div>
            </b-link>
          </div>
        </div>
        <div class="row justify-content-end">
          <b-button  variant="primary" @click="Download" pill>
            <div class="d-none d-md-block" v-b-tooltip.hover title="Crear Reporte">
              <b-icon icon="file-excel"/> Crear Reporte
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Crear Reporte">
              <b-icon icon="file-excel"/>
            </div>
          </b-button>
        </div>
      </template>
    </b-container>
    <template v-slot:modal-footer>
      <simple-modal-footer @ClickEvent="CloseModal"/>
    </template>
  </b-modal>
  <!-- MODAL -->
</div>
</template>
<script>
import moment from 'moment'
import generatorLogicInfo from '@/views/geocycle/service_plannings/components/generatorLogicInfo'
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'weighingReport',
  mixins: [fractalMixin],
  components: {
    generatorLogicInfo,
  },
  data() {
    return {
      // Rutas
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'reports',
          breadcrumbName: 'Reportes',
          bIcon: '',
        },
        {
          name: 'LogisticReportMenu',
          breadcrumbName: 'Reporte de Logística',
          bIcon: '',
        },
        {
          breadcrumbName: 'Reporte de Requerimiento Logístico',
          aIcon: '',
        },
      ],
      // Loading combos
      // Barra de busqueda y filtros
      filterValue: '',
      filterService: undefined,
      filterCarrier: undefined,
      filterClient: '',
      filterEstatus: '',
      filterPlant: '',
      filterFromDate: '',
      filterToDate: '',
      filterMonth: '',
      filterYear: '',
      // combos de filtros
      ServiceCombo: [],
      CarrierCombo: [],
      ClientsCombo: [],
      ServiceCombo4: [
        { value: '1', text: 'Asignado' },
        { value: '2', text: 'Sin Asignar' },
      ],
      // Table
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Información del Servicio',
            dataIndex: '',
            key: 'saprogramming',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'saprogramming',
            },
          },
          {
            title: 'Corrientes',
            dataIndex: 'servicePlannings.servicePlanningsMaterials',
            class: 'small text-center',
            scopedSlots: { customRender: 'currents' },
            // width: '20%',
          },
          {
            title: 'Descripción',
            dataIndex: '',
            key: 'service',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'service',
            },
          },
          {
            title: 'Fecha y Hora de Recolección',
            dataIndex: '',
            key: 'dates',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'dates',
            },
          },
          {
            title: 'Peso Aproximado (TON)',
            dataIndex: '',
            key: 'weight',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'weight',
            },
          },
          {
            title: 'Estatus',
            key: 'status',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'status' },
            // width: '20%',
          },
        ],
      },
      spinnerLoad: false,
      fadeAlter: false,
      fadeMsg: '',
      fileUrl: '',
    }
  },
  methods: {
    async FetchTable(pagEvent = {}) {
      const shiftArray = []
      const params = {
        'page[number]': (Object.keys(pagEvent).length !== 0) ? `${this.tableObj.pagination.current}` : `${this.tableObj.currentPage}`,
        'page[size]': `${this.tableObj.pagination.pageSize}`,
        include: 'weightRegistries,' +
          'trucks.logisticsShipmentTypes,' +
          'servicePlannings.workCenters,' +
          'servicePlannings.manifests,' +
          'servicePlannings.servicePlanningsMaterials.currents,' +
          'servicePlannings.service_planeable,' + 'trucks.carrierCompanies,' + 'servicePlannings.servicePlanningsPeriods',
      }
      if (this.filterService !== '') params['filter[servicePlannings]'] = this.filterService
      if (this.filterPlant !== '') params['filter[workCenters]'] = this.filterPlant
      if (this.filterCarrier !== '') params['filter[carrierCompanies]'] = this.filterCarrier
      if (this.filterClient !== '') params['filter[clients]'] = this.filterClient
      if (this.filterFromDate !== '' && this.filterToDate !== '') {
        params['filter[serviceProgramDated]'] = `${this.filterFromDate},${this.filterToDate}`
      }
      await this.GetTableResource(pagEvent, 'servicePlanningsCarriers', this.tableObj, params)
      if (this.filterEstatus !== '') {
        if (this.filterEstatus === '1') {
          this.tableObj.data.forEach(element => {
            if (element.trucks.carrierCompanies !== null) {
              if (element.trucks.carrierCompanies.name !== '') {
                shiftArray.push(element)
              }
            }
          })
          this.tableObj.data = []
          shiftArray.forEach(element => {
            this.tableObj.data.push(element)
          })
        } else {
          this.tableObj.data.forEach(element => {
            if (element.trucks.carrierCompanies === null) {
              shiftArray.push(element)
            }
          })
          this.tableObj.data = []
          shiftArray.forEach(element => {
            this.tableObj.data.push(element)
          })
        }
        // this.tableObj.pagination.total = this.tableObj.data.length
      }
    },
    async FetchCombos() {
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      // this.ClientsCombo = await this.GetResource('/clients', params1)
      // this.CarrierCombo = await this.GetResource('/carrierCompanies', params1)
      const params = {
        orderField: 'id',
        orderType: 'desc',
      }
      // this.ServiceCombo = await this.GetResource('/servicePlannings', params)
      const arr = [
        {
          path: '/fetch/clients?pageSize=1000',
          params: params1,
        },
        {
          path: '/fetch/carrierCompanies?pageSize=1000',
          params: params1,
        },
        {
          path: '/fetch/servicePlannings?pageSize=1000',
          params: params,
        },
      ]
      const [clients, carriers, services] = await this.GetConcurrencyResources(arr)
      this.ClientsCombo = clients
      this.CarrierCombo = carriers
      this.ServiceCombo = services
      // console.log('Respuesta view: ', serv)
    },
    async Download() {
      if (this.filterMonth && this.filterYear) {
        const params = {
          strategy: 'LogisticWeighing',
          service: this.filterService,
          plant: this.filterPlant,
          month: this.filterMonth,
          year: this.filterYear,
        }
        this.spinnerLoad = true
        await this.AxiosPost('downloadReport', params, async(res) => {
          window.open(res.fileUrl, '_blank')
          console.log(res.fileUrl)
          this.fileUrl = res.fileUrl
          this.spinnerLoad = false
          // this.CloseModal()
        }, () => {
          this.fileUrl = ''
          this.spinnerLoad = false
          this.fadeAlter = true
          this.fadeMsg = 'Ha ocurrido un error con la descarga del reporte.'
        })
      } else {
        this.fadeAlter = true
        this.fadeMsg = 'Debe seleccionar el mes y año para la exportación de información'
      }
    },
    OpenModal() {
      this.$refs.downloadModal.show()
      if (this.filterToDate) {
        this.filterMonth = (moment(this.filterToDate).month() + 1).toString()
        this.filterYear = (moment(this.filterToDate).year()).toString()
      }
    },
    CloseModal() {
      this.$refs.downloadModal.hide()
      this.filterMonth = ''
      this.filterYear = ''
      this.fileUrl = ''
    },
    // Auxiliares
  },
  async mounted() {
    await this.FetchCombos()
    await this.FetchTable()
  },
}
</script>
<style scoped></style>
